import { ApolloProvider } from "@apollo/client";
import { useRouter } from 'next/router';

import { initializeApollo, useApollo } from "../apollo";
import { PageDataProvider } from "./page-data-provider";

import { Sections } from "../../components/sections";
import Layout from "../../components/layout";
import { getAbsoluteUrl } from "../utils/get-absolute-url";

import SeoMetadata from "../../components/shared/seo-metadata";
import GlobalMicrodata from "../../components/microdata/global-microdata";
import PageMicrodata from "../../components/microdata/page-microdata";
import WebPreviewBanner from "../../components/webpreview";

import {
  GET_PAGES,
  GET_SLIDER,
  GET_MODALS,
  GET_REDIRECTS,
  getPageBySlug,
  GET_BANNERS,
} from "../fragments";

const DynamicPage = (pageProps) => {
  const apolloClient = useApollo(pageProps);
  const { pageContext } = pageProps;
  const router = useRouter();
  const isPreviewMode = router.isPreview;

  return (
    <ApolloProvider client={apolloClient}>
      <PageDataProvider pageData={pageProps}>
        <GlobalMicrodata
          microdata={pageProps.globalMicrodata}
          pageContext={pageContext}
          locale={pageProps.locale}
        />
        <PageMicrodata
          microdata={pageContext.microdata}
          locale={pageProps.locale}
        />
        <SeoMetadata
          {...pageContext.seoMetadata}
          absoluteUrl={pageContext.absoluteUrl}
        />
        <Layout pageProps={pageProps}>
          {isPreviewMode && (<WebPreviewBanner />)}
          {!pageContext?.sections.length && <h1>{pageContext?.title}</h1>}
          <Sections sections={pageContext?.sections} />
        </Layout>
      </PageDataProvider>
    </ApolloProvider>
  );
};

export async function getLocaleStaticPaths({ locale, context }) {
  const apolloClient = initializeApollo();
  // Get all pages from Strapi
  const response = await apolloClient.query({
    query: GET_PAGES,
    variables: { locale },
  });

  const paths = response.data.pages.map(({ slug }) => {
    // Decompose the slug that was saved in Strapi
    let slugArray = !slug ? false : slug.split("/");

    return {
      params: { slug: slugArray },
    };
  });

  return { paths, fallback: false };
}

const getModals = async ({ apolloClient, locale }) => {
  const { data } = await apolloClient.query({
    query: GET_MODALS,
    variables: {
      locale: locale,
    },
  });
  const modals = data.modals || [];
  return modals.reduce((acc, modal) => {
    acc[modal.id] = modal;
    return acc;
  }, {});
};

const getBanners = async ({ apolloClient, locale }) => {
  const { data } = await apolloClient.query({
    query: GET_BANNERS,
    variables: {
      locale: locale,
    },
  });
  const banners = data.banners || [];
  return banners.reduce((acc, banner) => {
    acc[banner.id] = banner;
    return acc;
  }, {});
};


const fetchSliderSections = async ({ apolloClient, locale, sections }) => {
  if (sections?.length) {
    const promises = sections.map((section) => {
      if (section.__typename === "ComponentSectionsSlider") {
        return new Promise(async (resolve) => {
          const response = await apolloClient.query({
            query: GET_SLIDER,
            variables: {
              locale,
              sliderId: section.slider.id,
            },
          });
          return resolve({ ...section, slider: response.data.sliders[0] });
        });
      }
      return Promise.resolve(section);
    });
    return await Promise.all(promises);
  }
  return sections;
};

export async function getLocaleStaticProps({ locale, context }) {
  const apolloClient = initializeApollo();
  const { params, preview } = context;
  const slug = params.slug || [""];

  const publicationState = preview ? "PREVIEW" : "LIVE";

  const pageDataRequest = apolloClient.query({
    query: getPageBySlug(),
    variables: {
      slug: slug.join("/"),
      locale,
      publicationState
    },
  });

  const modalsRequest = getModals({ apolloClient, locale });
  const bannersRequest = getBanners({ apolloClient, locale });

  const [pageData, modals, banners] = await Promise.all([
    pageDataRequest,
    modalsRequest,
    bannersRequest
  ]);

  const absoluteUrl = getAbsoluteUrl({ locale, slug: params.slug });
  const page = pageData.data.pages[0];

  const pageContext = {
    ...page,
    sections: await fetchSliderSections({
      sections: page.sections,
      locale,
      apolloClient,
    }),
  };

  if (!pageContext) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      locale: locale,
      header: pageData.data.header,
      footer: pageData.data.footer,
      banners,
      modals,
      globalMicrodata: pageData.data.microdatum,
      faqsCommon: pageData.data.faqsCommon,
      pageContext: {
        ...pageContext,
        absoluteUrl,
      },
    },
  };
}

export default DynamicPage;
