import DynamicPage, { getLocaleStaticPaths, getLocaleStaticProps } from "../lib/page-components/pages";

export async function getStaticPaths(context) {
  return getLocaleStaticPaths({ locale: 'es', context });
}
export async function getStaticProps(context) {
  const { params } = context;

  const slug = params.slug || [];
  if (slug[0] === 'api') {
    const previewSlug = slug.slice(1);
    return getLocaleStaticProps({ locale: 'es', context: { ...context, params: { slug: previewSlug } } });
  }

  // Handle normal pages
  return getLocaleStaticProps({ locale: 'es', context });
}

export default DynamicPage;